import React from 'react';
import styled from 'styled-components';
import Wave2Img from './../../assets/images/wave2.png';
import Wave2Mobile from './../../assets/images/wave2-mobile.png';
import Wave21 from './../../assets/images/wave21.png';
import Wave21Mobile from './../../assets/images/wave21-mobile.png';
import Wave22 from './../../assets/images/wave22.png';
import Wave22Mobile from './../../assets/images/wave22-mobile.png';
const Wave = styled.div`
    margin: 4px 0;
    & > img.desktop {
        display: block;
    }
    & > img.mobile {
        display: none;
    }
    @media screen and (max-width: 767px) {
        & > img.mobile {
            display: block;
        }
        & > img.desktop {
            display: none;
        }
    }
`;

export const Wave3 = () => {
    return (
        <Wave>
            <img
                width="100%"
                src={Wave2Img}
                className="desktop"
                alt="wave3-desktop"
            />
            <img
                width="100%"
                src={Wave2Mobile}
                className="mobile"
                alt="wave3-mobile"
            />
        </Wave>
    );
};
export const Wave1 = () => {
    return (
        <Wave>
            <img
                width="100%"
                src={Wave21}
                className="desktop"
                alt="wave1-desktop"
            />
            <img
                width="100%"
                src={Wave21Mobile}
                className="mobile"
                alt="wave1-mobile"
            />
        </Wave>
    );
};
export const Wave2 = () => {
    return (
        <Wave>
            <img
                width="100%"
                src={Wave22}
                className="desktop"
                alt="wave2-desktop"
            />
            <img
                width="100%"
                src={Wave22Mobile}
                className="mobile"
                alt="wave2-mobile"
            />
        </Wave>
    );
};
